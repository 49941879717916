const { translate } = require('./locale')

async function addressToStr(address, lang = 'ru') {
    let str = ''
    let {
        country = '',
        region,
        locality,
        localityType,
        street,
        buildingNumber,
        floor,
        apartment,
        extra = '',
        final_locality
    } = address

    locality = final_locality || locality

    if(!country || !isNaN(country)) country = await getFromDB(country, 'countries')
    if(!isNaN(region)) region = await getFromDB(region, 'regions')
    if(!isNaN(locality)) locality = await getFromDB(locality, 'localities')

    // localityType: { title: locality.type.title, short: locality.type.short }

    let streetLocale = translate('address.street', lang),
        buildingNumberLocale = translate('address.buildingNumber', lang),
        floorLocale = translate('address.floor', lang),
        apartmentLocale = translate('address.apartment', lang)

    if(country) str += country
    if(region) str += ', ' + region
    if(locality) str += ', ' + locality
    if(street) str += ', ' + streetLocale + ' ' + street
    if(buildingNumber) str += ', ' + buildingNumberLocale + ' ' + buildingNumber
    // if(floor) str += ', ' + floor + ' ' + floorLocale
    if(apartment) str += ', ' + apartmentLocale + ' ' + apartment
    if(extra) str += ', ' + extra

    return clearDublicateLocalities(str)
}

function shortAddress(address) {
    let str = ''
    let {
        street,
        buildingNumber,
        apartment,
        extra = ''
    } = address

    let arr = Object.values(address)

    if(street) str += street
    if(buildingNumber) str += ', ' + buildingNumber
    if(apartment) str += ', ' + apartment
    if(extra) str += ', ' + extra

    str = arr.join(', ')

    return clearDublicateLocalities(str)
}

function clearDublicateLocalities(str) {
    const arr = str.split(', ')
    return arr.filter((value, index) => arr.indexOf(value) === index).join(', ')
}

async function getFromDB(item, table) {
    let q = await db.selectOne(table, { filter: { id: item } })

    if(q) return q.title
    return item
}

async function getZipcode(localityId) {
    const t = 'localities'
    let locality = await db.selectOne(t, {
        filter: { id: localityId }
    })

    if(locality && locality.zip_code) return locality.zip_code
    return null
}

async function getNearestZipcode(localityId) {
    const locality = await db.selectOne('localities', {
        filter: { id: localityId }
    })

    if(!locality) return null

    if(locality.zip_code) return locality.zip_code

    const [relationsResult] = await db.execute(
        `SELECT l.zip_code
            FROM localities l
            WHERE 
            (l.parent_id = ? OR l.id = ? OR EXISTS (
                SELECT 1 
                FROM localities sub_l 
                WHERE sub_l.parent_id = l.id AND sub_l.id = ?
            ))
            AND COALESCE(l.zip_code, '') <> ''
            LIMIT 1`,
        [localityId, localityId, localityId]
    )

    if(relationsResult.zip_code) return relationsResult.zip_code

    const [regionResult] = await db.execute(
        `SELECT zip_code
            FROM localities
            WHERE region_id = ? AND zip_code != ''
            LIMIT 1`,
        [locality.region_id]
    )

    if(regionResult.zip_code) return regionResult.zip_code

    return null
}

async function checkLocalityByKato(locality) {
    if(!locality) return false

    let q = await db.selectOne('localities', { filter: { kato_code: locality } })

    return q?.id || false
}

async function addRecipientAddress(recipientId, addressId) {
    let t = 'recipients_addresses'

    let check = await db.selectOne(t, { filter: { recipient_id: recipientId, address_id: addressId } })

    if(check) return check.id
    
    let q = await db.insert(t, { recipient_id: recipientId, address_id: addressId })

    return q || false
}

async function getLocality(id) {
    let q = await db.selectOne('localities', { filter: { id: id } })
    return q
}

async function localityPath(localityId) {
    let locality = await getLocality(localityId)
    if(!locality) return ''

    if(locality.parent_id) {
        let parentPath = await localityPath(locality.parent_id)
        if(!parentPath) return locality.title

        return `${parentPath}, ${locality.title}`
    } else {
        return locality.title
    }
}

async function fullLocalityPath(localityId) {
    let locality = await getLocality(localityId)
    if(!locality) return ''
    let region = ''
    let country = ''

    if(!isNaN(locality.region_id)) region = await db.selectOne('regions', { filter: { id: locality.region_id } })
    if(region && !isNaN(region.country_id)) country = await db.selectOne('countries', { filter: { id: region.country_id } })

    let _localityPath = await localityPath(localityId)

    if(region) _localityPath = `${region.title}, ${_localityPath}`
    if(region && country) _localityPath = `${country.title}, ${_localityPath}`

    return _localityPath
}

/**
 * 
 * @param {int} localityId - id населенного пункта
 * @returns localityId
 * меняет localityId н.п. если это район города на localityId города
 * для привязывания улиц к городам а не районам городов
 */
async function getTrueLocalityId(localityId) {
    if(!localityId) return false
    
    let locality = await db.query(`SELECT l.*, lp.id AS p_id, lp.region_id AS p_region_id, lp.title AS p_title, lp.zip_code AS p_zip_code, lp.type_id AS p_type_id, lp.kato_code AS p_kato_code, lp.parent_id AS p_parent_id FROM localities l, localities lp WHERE l.id = ${localityId} AND l.parent_id = lp.id`)

    if(locality[0]) {
        if(locality[0].title.includes('район ') && (locality[0].p_title.includes('с. ') || locality[0].p_title.includes('г. '))) localityId = locality[0].p_id
    }
    
    return localityId
}

module.exports = {
    addressToStr,
    shortAddress,
    getFromDB,
    getNearestZipcode,
    getZipcode,
    checkLocalityByKato,
    addRecipientAddress,
    localityPath,
    fullLocalityPath,
    getTrueLocalityId
}