<template>
    <header ref="header" :class="{ 'header-scrolled': isScrolled, 'toggled': toggled, 'inner-page': innerPage }">
        <div class="container mt-3 mb-3">
            <div class="row">
                <div class="col-md-3 col-9">
                    <div class="logo">
                        <router-link :to="{ path: `${this.mainPageLocalized}` }" class="navbrand">
                            <img v-if="locale=='ru' || locale=='en'" src="../../assets/img/logo-text-ru-white.png" :alt="$t('header.logo_alt')">
                            <img v-else-if="locale=='kk' "src="../../assets/img/logo-text-kz-white.png" :alt="$t('header.logo_alt')">
                            <img v-else src="../../assets/img/logo-text-ru-white.png" :alt="$t('header.logo_alt')">
                        </router-link>
                    </div>
                </div>
                <div class="col-3 d-flex justify-content-end align-items-center d-md-none">
                    <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#header-collapse" aria-expanded="false" aria-controls="header-collapse" :disabled="togglerDisabled" @click="togglerSwitch">
                        <i class="bi bi-list"></i>
                    </button>
                </div>
                <div class="collapse col d-md-flex justify-content-end align-items-center" id="header-collapse" ref="collapseElement">
                    <div class="row align-items-center">
                        <div class="col-12 col-md-auto d-flex align-items-center justify-content-center mt-3 my-md-0">
                            <LangSwitcher />
                        </div>
                        <div v-if="this.$store.state.user" class="col-12 col-md-auto d-flex align-items-center justify-content-center mt-3 my-md-0">
                            <router-link to="/cabinet" class="nav-item rounded text-center user-link">
                                <span class="header-username text-truncate">
                                    {{ this.$store.state.user.username }}
                                </span>
                                <i class="bi bi-person-fill mx-1"></i>
                            </router-link>
                        </div>
                        <div v-if="!this.$store.state.user" class="col-12 col-md-auto d-flex align-items-center justify-content-center mt-3 my-md-0">
                            <router-link v-if="!this.$store.state.user" to="/login/main" class="nav-item rounded text-uppercase">
                                {{ $t('header.login_link') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>

import LangSwitcher from './LangSwitcher.vue'

export default {
    data: () => ({
        isScrolled: false,
        toggled: false,
        togglerDisabled: false,
        delay: 350
    }),
    computed: {
        locale() {
            const locale = this.$i18n.locale;
            return locale
        },
        mainPageLocalized() {
            if(this.locale == 'ru' || this.locale == undefined)
                return '/'
            else return '/' + this.locale + '/'
        }
    },
    props: {
        innerPage: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LangSwitcher
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            if(window.scrollY > 60) {
                this.isScrolled = true
            } else {
                this.isScrolled = false
            }

            if(this.$refs.collapseElement.classList.contains('show')){
                if(window.pageYOffset > 0) {
                    this.$refs.collapseElement.classList.toggle('show')
                    this.togglerSwitch()
                }
            }
        },
        togglerSwitch() {
            this.toggled = !this.toggled
            this.togglerDisabled = true

            setTimeout(() => {
                this.togglerDisabled = false
            }, this.delay);
        }
    }
}

</script>

<style scoped>

    header {
        flex: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        -webkit-transition: all ease-out .5s;
        -moz-transition: all ease-out .5s;
        -o-transition: all ease-out .5s;
        transition: all ease-out .5s;
    }

    .collapse.show {
        -webkit-transition: all ease-out .5s;
        -moz-transition: all ease-out .5s;
        -o-transition: all ease-out .5s;
        transition: all ease-out 0.5s;
    }

    .header-scrolled,
    .toggled,
    .inner-page {
        background-color: #321d51;
    }
    
    .logo img {
        max-width: 180px;
        max-height: 63px;
    }

    .header-username {
        position: relative;
        display: inline-block;
        width: fit-content;
        max-width: 150px;
        overflow: hidden;
    }

    .nav-item {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        margin-left: 0;
        background-color: #e5e3ff;
        color: #132671;
        text-decoration: none;
        align-items: center;
        justify-content: center;
    }

    .nav-item:hover {
        color: #9082d9;
        transition: .25s;
        -webkit-transition: .25s;
        -o-transition: .25s;
        -moz-transition: .25s;
    }

    .navbar-toggler {
        color: #ffd6ce;
        font-size: 2rem;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }
    }

    @media (max-width: 576px) {
        .user-link {
            width: fit-content;
            max-width: 200px;
        }
    }

</style>