const { generate } = require("./generator")
const { translate } = require("./locale")
const { addressToStr, checkLocalityByKato, addRecipientAddress, getTrueLocalityId } = require("./location")
const { getPaginationObjByOptions } = require("./pagination")
const { getUsername } = require("./user")

const table = 'debt_notices'

const statuses = {
    draft: -1,
    unread: 0,
    read: 1,
    expired: 2
}

async function getNotices(senderIds, options = {}) {
    if(senderIds.length === 0) return []
    let pagination = getPaginationObjByOptions(options)
    
    let q = []
    let totalSql = `SELECT COUNT(*) as count FROM ${table} dn JOIN addresses a ON dn.address_id = a.id WHERE sender IN (${senderIds.join(', ')})`
    let sql = `SELECT dn.*, ra.recipient_id, a.locality_id, s.title AS street, a.building_number, a.apartment, a.extra, a.final_locality FROM ${table} dn JOIN addresses a ON dn.address_id = a.id JOIN recipients_addresses ra ON a.id = ra.address_id JOIN streets s ON s.id = a.street WHERE sender IN (${senderIds.join(', ')})`

    // order by (key)
    if(pagination.sortBy) {
        sql += ` ORDER BY ${pagination.sortBy}`
    } else {
        sql += ` ORDER BY created`
    }

    // order type (asc / desc)
    if(pagination.sortType) {
        sql += ` ${pagination.sortType}`
    } else {
        sql += ` DESC`
    }

    // limit (per page)
    if(pagination.limit) sql += ` LIMIT ${pagination.limit}`

    // offset ((page - 1) * limit)
    if(pagination.offset) sql += ` OFFSET ${pagination.offset}`

    q = await db.query(sql)

    let total = await db.query(totalSql)

    return {
        data: q,
        total: total[0].count
    }
}

async function getJustReadNotice(senderIds) {
    let q = []

    q = await db.query(`SELECT dn.*, ra.recipient_id, a.locality_id, s.title AS street, a.building_number, a.apartment, a.extra, a.final_locality FROM ${table} dn JOIN addresses a ON dn.address_id = a.id JOIN recipients_addresses ra ON a.id = ra.address_id JOIN streets s ON s.id = a.street WHERE sender IN (${senderIds.join(', ')}) AND recipient <> 0 AND status <> ${statuses.draft} AND sender_notified = 0 LIMIT 1`)

    return q[0] || false
}

async function getInbox(recipients) {
    if(recipients.length === 0) return []

    let q = []

    q = await db.query(`SELECT dn.*, ra.recipient_id, a.locality_id, s.title AS street, a.building_number, a.apartment, a.extra, a.final_locality, r.title, r.company_name FROM ${table} dn JOIN addresses a ON dn.address_id = a.id JOIN recipients r ON dn.sender = r.id JOIN recipients_addresses ra ON a.id = ra.address_id JOIN streets s ON s.id = a.street WHERE status <> ${statuses.draft} AND recipient IN (${recipients.join(', ')})`)

    return q
}

async function getInboxByAddressId(addressId) {
    if(!addressId) return []

    let q = []
    q = await db.query(`SELECT dn.*, ra.recipient_id, a.locality_id, s.title AS street, a.building_number, a.apartment, a.extra, r.title, r.company_name FROM ${table} dn JOIN addresses a ON dn.address_id = a.id JOIN recipients r ON dn.sender = r.id JOIN recipients_addresses ra ON a.id = ra.address_id JOIN streets s ON s.id = a.street WHERE status <> ${statuses.draft} AND dn.address_id = ${addressId}`)

    return q
}

async function getAddressIdByUserId(userId) {
    if(!userId) return false
    
    let addressSetting = await db.selectOne('user_settings', { filter: { user_id: userId, key: 'address_id' } })

    if(!addressSetting) return false

    return addressSetting.value
}

async function checkNotices(recipient) {
    let q = []

    q = await db.query(`SELECT dn.id, ra.recipient_id FROM ${table} dn JOIN addresses a ON dn.address_id = a.id JOIN recipients_addresses ra ON a.id = ra.address_id WHERE status <> ${statuses.draft} AND recipient = ${recipient}`)

    return q
}

async function checkNoticesByAddressId(addressId) {
    let q = []

    q = await db.query(`SELECT dn.id, ra.recipient_id FROM ${table} dn JOIN addresses a ON dn.address_id = a.id JOIN recipients_addresses ra ON a.id = ra.address_id WHERE status <> ${statuses.draft} AND dn.address_id = ${addressId}`)

    return q
}

async function getOldDebtNotices(timestamp) {
    let q = await db.execute(
        `SELECT * from debt_notices WHERE created < ? AND status NOT IN (?, ?);`,
        [timestamp, statuses.draft, statuses.expired])

    return q
}

async function updateOldDebtNoticeStatus(id) {
    let q = await db.execute(`UPDATE ${table} SET status = ${statuses.expired} 
    WHERE id = ?;`,
    [id])

    return q
}

async function getNotice(id) {
    let q = []

    q = await db.query(`SELECT dn.*, ra.recipient_id, a.locality_id, s.title AS street, a.building_number, a.apartment, a.extra, a.final_locality FROM ${table} dn JOIN addresses a ON dn.address_id = a.id JOIN recipients_addresses ra ON a.id = ra.address_id JOIN streets s ON s.id = a.street WHERE dn.id = ${id}`)

    return q ? q[0] : false
}

async function getUnreadNotice(recipientId) {
    let q = await db.query(`SELECT dn.*, ra.recipient_id, a.locality_id, s.title AS street, a.building_number, a.apartment, a.extra, a.final_locality FROM ${table} dn JOIN addresses a ON dn.address_id = a.id JOIN recipients_addresses ra ON a.id = ra.address_id JOIN streets s ON s.id = a.street WHERE dn.recipient = ${recipientId} AND dn.status = ${statuses.unread}`)

    return q[0] || false
}

async function addAddress(address, sender) {
    let t = 'addresses',
        data = {}

    if(address?.locality_id || address?.locality || address?.region) data.locality_id = address.locality_id || address.locality || address.region
    if(data.locality_id) data.final_locality = data.locality_id
    data.locality_id = await getTrueLocalityId(data.locality_id) // if city district id, change to city id
    if(address?.street) {
        let _street = await addStreet({ title: address.street, localityId: data.locality_id })
        if(_street) data.street = _street?.id || _street
    }
    if(address?.buildingNumber || address?.building_number) data.building_number = address?.buildingNumber || address?.building_number
    data.floor = address?.floor
    if(address?.extra) data.extra = address?.extra
    if(address?.apartment) data.apartment = address?.apartment

    let filter = {
        locality_id: data.locality_id,
        street: data.street,
        building_number: data.building_number,
        floor: data.floor
    }
    if(data.apartment) filter.apartment = data.apartment

    let check = await db.selectOne(t, { filter: filter }) // check if address exists

    let q = false // address id
    let recipient = 0

    if(!check) {
        if(Object.keys(data).length) q = await db.insert(t, data)
    } else {
        if(data.locality_id !== data.final_locality) {
            await db.update(t, {
                set: { final_locality: data.final_locality },
                filter: { id: check.id }
            })
        }

        await addAddressVisibility(check.id, sender.user_id)
        let recipientAddress = await db.select('recipients_addresses', { filter: { address_id: check.id } })
        if(recipientAddress && recipientAddress.length > 0) {
            recipient = recipientAddress[0].recipient_id
        }
        q = check.id

        return {
            recipient: recipient,
            addressId: check.id
        }
    }

    if(q) {
        await addRecipientAddress(0, q)

        await addAddressVisibility(q, sender.user_id)

        return {
            recipient: recipient,
            addressId: q
        }
    }

    return false
}

async function createNotice(notice) {
    let check = await db.selectOne(table, { filter: { slug: notice.slug } })
    let q = false

    if(!check) {
        q = await db.insert(table, notice)
    } else {
        q = await db.update(table, {
            set: notice,
            filter: {
                slug: notice.slug
            }
        })
    }

    return q
}

async function addAddressVisibility(addressId, userId) {
    let t = 'users_addresses_visibility'

    let check = await db.selectOne(t, {
        filter: {
            address_id: addressId,
            user_id: userId
        }
    })

    let q = false

    if(!check) {
        q = await db.insert(t, {
            address_id: addressId,
            user_id: userId
        })
    }

    return q
}

async function checkDebtNotices(addressId) {
    let notices = []

    notices = await db.select(table, {
        filter: {
            address_id: addressId
        }
    })

    if(notices.length > 0) return notices

    return false
}

async function checkImportedNotice(index, notice) {
    index = parseInt(index)
    let locality = notice[0],
        street = notice[1],
        buildingNumber = notice[2],
        apartment = notice[3],
        amountOwed = notice[4],
        errors = []

        if(!locality) errors.push({ cell: `C${index+1}`, message: 'No locality provided'})
        if(!street) errors.push({ cell: `D${index+1}`, message: 'No street provided'})
        if(!buildingNumber) errors.push([`E${index+1}`, 'No building number provided'])
        if(!amountOwed) errors.push({ cell: `G${index+1}`, message: 'No amount owed provided'})

    let localityId,
        streetId

    if(!isKatoCode(locality)) errors.push({ cell: `C${index+1}`, message: 'Error in locality'})

    localityId = await checkLocalityByKato(locality)
    if(localityId) {
        streetId = await checkStreet(street, localityId)

        if(streetId) {
            if(!buildingNumber) {
                errors.push({ cell: `E${index+1}`, message: 'Error in building number' })
            }
        } else {
            errors.push({ cell: `D${index+1}`, message: 'Error in street' })
        }
    } else {
        errors.push({ cell: `C${index+1}`, message: 'Error in locality' })
    }

    if(!isAmount(amountOwed)) errors.push({ cell: `G${index+1}`, message: 'Incorrect amount owed'})

    if(errors.length) return { result: 'ERROR', errors: errors}
    return { result: 'SUCCESS'}
}

async function checkStreet(street, locality) {
    if(!/^[\p{L}0-9\s.'-]+$/u.test(street) || !street) return false

    let q = await db.query(`SELECT * FROM \`streets\` WHERE LOCATE('${street}', \`title\`) AND \`locality_id\` = ${locality}`)

    return q[0] ? q[0].id : 'no street'
}

function standardizeRegion(region) {
    region = region
        .replace('вко', 'Восточно-Казахстанская область')
        .replace('восточно казахстанская область', 'Восточно-Казахстанская область')
        .replace('ско', 'Северо-Казахстанская область')
        .replace('северо казахстанская область', 'Северо-Казахстанская область')
        .replace('зко', 'Западно-Казахстанская область')
        .replace('западно казахстанская область', 'Западно-Казахстанская область')
        .replace('жетысу', 'Жетісу')
        .replace('жетысуйская область', 'область Жетісу')
        .replace('улытау', 'Ұлытау')
        .replace('Абайская область', 'Абай')
        .replace('абайская область', 'Абай')
    
    return region
}

/**
 * Standardizes locality names to check in DB
 * - if not one of five standard locality objects, returns false
 * @param {string} locality - locality name
 */
function standardizeLocality(locality) {
    locality = locality
        .replace(/город\s/ig, 'г.')
        .replace(/г\s/ig, 'г. ')
        .replace(/городской\sакимат/ig, 'Г. А.') // not needed
        .replace(/городская\sадминистрация/ig, 'г. а.') // not needed
        .replace(/поселковая\sадминистрация/ig, 'п. а.') // not needed
        .replace(/районная\sадминистрация/ig, 'р. а.') // not needed
        .replace(/сельская\sадминистрация/ig, 'с. а.') // not needed
        .replace(/аульный\sокруг/ig, 'а. о.') // not needed
        .replace(/сельский\sокруг/ig, 'с. о.') // not needed
        .replace(/поселок/ig, 'п.')
        .replace(/п\s/ig, 'п. ')
        .replace(/село/ig, 'с.')
        .replace(/с\s/ig, 'с. ')
        .replace(/аул/g, 'а.')
        .replace(/а\s/ig, 'а. ')
        .replace(/подсобное\sхозяйство/ig, 'подхоз') // not needed
        .replace(/крестьянское\sхозяйство/ig, 'кр. хоз') // not needed
        .replace(/кр.\sхоз./ig, 'кр. хоз') // not needed
        .replace(/крестьянский\sпоселок/ig, 'крес. пос.') // not needed
        .replace(/лесничье\sхозяйство/ig, 'лесхоз') // not needed
        .replace(/сельское\sхозяйство/ig, 'селхоз') // not needed
        .replace(/машинный\sдвор/ig, 'маш. двор') // not needed
        .replace(/разъезд\sКазахстанский/ig, 'р. Казахстанский') // not needed
        .replace(/разъезд\s/ig, 'рзд. ') // not needed
        .replace(/отгон/ig, 'отг.') // not needed
        .replace(/станция/ig, 'ст.')
        .replace(/ст\s/ig, 'ст. ')
    
    if(locality.includes('Г. А.') || 
        locality.includes('г. а.') ||
        locality.includes('п. а.') || 
        locality.includes('р. а.') || 
        locality.includes('с. а.') || 
        locality.includes('а. о.') || 
        locality.includes('с. о.') || 
        locality.includes('подхоз') || 
        locality.includes('кр. хоз') || 
        locality.includes('крес. пос.') || 
        locality.includes('лесхоз') || 
        locality.includes('селхоз') || 
        locality.includes('маш. двор') || 
        locality.includes('р. Казахстанский') || 
        locality.includes('рзд.') || 
        locality.includes('отг.')) {
            return
        }

    return locality
}

function isAmount(amount) {
    amount = Number(amount)
    return !!(typeof amount == 'number' && !isNaN(amount))
}

async function checkAddress(data) {
    let locality = data[0],
        street = data[1],
        buildingNumber = data[2],
        apartment = data[3]

    if(!isKatoCode(locality)) return false

    let localityId = await checkLocalityByKato(locality)

    if(!localityId) return false
    let finalLocality = localityId

    localityId = await getTrueLocalityId(localityId)

    let _street = await db.selectOne('streets', { filter: { title: street, locality_id: localityId } })

    let check = await db.selectOne('addresses', {
        filter: {
            locality_id: localityId,
            street: _street?.id || '',
            building_number: buildingNumber,
            apartment: apartment || ''
        }
    })

    if(check) return check.id

    let newAddressId = await db.insert('addresses', {
        // recipient_id: 0,
        locality_id: localityId,
        street: _street?.id || '',
        building_number: buildingNumber,
        floor: 0,
        apartment: apartment || '',
        final_locality: finalLocality
    })

    return newAddressId
}

async function uploadNotices(notices = []) {
    if(!notices.length) return false

    let cols = Object.keys(notices[0]).join(', ')

    let rows = []

    for(let n in notices) {
        rows.push(`(${notices[n].recipient}, ${notices[n].sender}, ${notices[n].occurrence_date}, ${notices[n].amount_owed}, ${notices[n].created}, ${notices[n].address_id})`)
    }

    let values = rows.join(', ')

    let sql = `INSERT INTO ${table} (${cols}) VALUES ${values}`

    let query = await db.query(sql)

    return query
    // if(query.affected)
}

async function createClaimDrafts(claims = []) {
    if(!claims.length) return false
    let count = 0

    for(let c in claims) {
        let address = await db.query(`SELECT a.id, ra.recipient_id, a.locality_id, s.title AS street, a.building_number, a.floor, a.apartment, a.extra, a.zip_code, a.final_locality FROM addresses a JOIN recipients_addresses ra ON a.id = ra.address_id JOIN streets s ON a.street = s.id WHERE a.id = ${claims[c].addressId}`)
        address = address.length ? address[0] : false
        if(!address) return false
        
        let addressString = await addressToStr(address)
        
        claims[c].template_fields = fillOsiTemplateFields({ objAddress: addressString })
        
        //TODO add language to debt notice
        claims[c].content = generate('osi', claims[c], claims[c].senderData, claims[c].recipientData)
        
        delete claims[c].addressId
        delete claims[c].recipientData
        delete claims[c].senderData

        let q = await db.insert('claims', claims[c])

        if(q) {
            let slug = generateShortCode(q)
            slug = await checkSlug(slug)

            await db.update('claims', {
                set: { slug: slug, template_fields: JSON.stringify(claims[c].template_fields) },
                filter: { id: q }
            })

            count++
        } else {
            return false
        }
    }
    return count
}

async function getReceiptById(receiptId) {
    let q = await db.selectOne(table, {
        filter: { id: receiptId }
    })

    return q
}

async function checkSlug(slug, table = 'claims') {
    let q = await db.selectOne(table, {
        filter: {
            slug: slug
        }
    })

    if(!q) return slug

    let slg = generateShortCode()

    return await checkSlug(slg)
}

function generateShortCode(number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let codeLength = 3 // По умолчанию используем 3 символа
  
    if (number >= 1000) {
      // Если число больше или равно 1000, используем количество символов равное числу разрядов
      codeLength = Math.ceil(Math.log10(number + 1)) // +1 для избежания log(0)
    }
  
    let shortCode = ''
    for (let i = 0; i < codeLength; i++) {
        shortCode += characters.charAt(Math.floor(Math.random() * characters.length))
    }
  
    return shortCode
}

function fillOsiTemplateFields(data) {
    return {
        "object_address": {
            "label": "Адрес объекта начисления",
            "type": "select",
            "field_value": data.objAddress,
            "options": [],
            "validations": [
                "required"
            ],
            "name": "object_address"
        }
    }
}

function isKatoCode(str) {
    if(!str) return false
    str = str.toString()

    const regex = /^\d{9}$/

    return regex.test(str)
}

async function checkNoticesByAddress(obj) {
    let t = 'addresses'

    let trueLocality = await getTrueLocalityId(obj?.locality_id)

    let street = await db.selectOne('streets', { filter: { title: obj.street, locality_id: trueLocality } })

    let filter = {
        locality_id: trueLocality,
        street: street?.id,
        building_number: obj?.building_number,
        apartment: obj?.apartment
    }

    let addresses = await db.select(t, {
        filter: filter
    })
    if(!addresses?.length) return { result: false, senders: [] }

    let addressIds = addresses.map(a => a.id)

    let q = await db.query(`SELECT * FROM ${table} WHERE status >= ${statuses.unread} AND address_id IN (${addressIds.join(', ')})`)

    let arr = []
    let data = []

    for(let i in q) {
        let sender = await db.selectOne('recipients', { filter: { id: q[i].sender } })
        let amount = parseFloat(q[i].amount_owed).toLocaleString('kk-KZ') + ' ₸'

        if(sender) arr.push(sender.company_name ? getUsername(sender) : '')
        if(sender) data.push({ type: translate('claims-check.debt-notice'), sender: sender.company_name ? getUsername(sender) : '', amount })
    }

    return { result: !!q.length, senders: arr, data: data }
}

async function getStreet(data) {
    const { title, localityId } = data

    let street = await db.selectOne('streets', {
        filter: { title: title, locality_id: localityId }
    })

    return street
}

async function addStreet(data) {
    const { title, localityId } = data
    let trueLocality = await getTrueLocalityId(localityId)

    let street = await getStreet({ title, localityId: trueLocality })

    if(street) return street.id

    let q = await db.insert('streets', { title: title, locality_id: trueLocality })

    return q
}

module.exports = {
    statuses,
    getNotices,
    getJustReadNotice,
    getInboxByAddressId,
    getNotice,
    getUnreadNotice,
    checkNotices,
    getOldDebtNotices,
    updateOldDebtNoticeStatus,
    addAddress,
    createNotice,
    checkDebtNotices,
    checkImportedNotice,
    checkAddress,
    getAddressIdByUserId,
    checkNoticesByAddressId,
    checkNoticesByAddress,
    uploadNotices,
    createClaimDrafts
}